/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "background-clip",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#background-clip",
    "aria-label": "background clip permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Background Clip"), "\n", React.createElement(_components.p, null, "Utilities for controlling the bounding box of an element's background."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "backgroundClip={keyword}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "background-clip: {keyword};"))))), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "backgroundClip={keyword}"), " utilities to control the bounding box of an element's background."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=indigo"
  }, "<>\n  <template preview>\n    <x.div display=\"grid\" gridTemplateColumns={{ _: 1, lg: 3 }} gap={2}>\n      <x.div\n        backgroundClip=\"border-box\"\n        p={6}\n        borderRadius=\"md\"\n        bg=\"indigo-600\"\n        border={4}\n        borderColor=\"indigo-300\"\n        borderStyle=\"dashed\"\n        fontWeight=\"extrabold\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n      >\n        <x.span py={2}>border-box</x.span>\n      </x.div>\n      <x.div\n        backgroundClip=\"padding-box\"\n        p={6}\n        borderRadius=\"md\"\n        bg=\"indigo-600\"\n        border={4}\n        borderColor=\"indigo-300\"\n        borderStyle=\"dashed\"\n        fontWeight=\"extrabold\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n      >\n        <x.span py={2}>padding-box</x.span>\n      </x.div>\n      <x.div\n        backgroundClip=\"content-box\"\n        p={6}\n        borderRadius=\"md\"\n        bg=\"indigo-600\"\n        border={4}\n        borderColor=\"indigo-300\"\n        borderStyle=\"dashed\"\n        fontWeight=\"extrabold\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n      >\n        <x.span py={2}>content-box</x.span>\n      </x.div>\n    </x.div>\n  </template>\n  <x.div\n    backgroundClip=\"border-box\"\n    p={6}\n    bg=\"indigo-600\"\n    border={4}\n    borderColor=\"indigo-300\"\n    borderStyle=\"dashed\"\n  />\n  <x.div\n    backgroundClip=\"padding-box\"\n    p={6}\n    bg=\"indigo-600\"\n    border={4}\n    borderColor=\"indigo-300\"\n    borderStyle=\"dashed\"\n  />\n  <x.div\n    backgroundClip=\"content-box\"\n    p={6}\n    bg=\"indigo-600\"\n    border={4}\n    borderColor=\"indigo-300\"\n    borderStyle=\"dashed\"\n  />\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "cropping-to-text",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cropping-to-text",
    "aria-label": "cropping to text permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cropping to text"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "backgroundClip=\"text\""), " to crop an element's background to match the shape of the text. Useful for effects where you want a background image to be visible through the text."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div\n      textAlign=\"center\"\n      py={6}\n      lineHeight={0}\n      letterSpacing=\"tight\"\n      fontSize=\"5xl\"\n      fontWeight=\"extrabold\"\n    >\n      <x.span\n        backgroundClip=\"text\"\n        color=\"transparent\"\n        backgroundImage=\"gradient-to-r\"\n        gradientFrom=\"emerald-500\"\n        gradientTo=\"light-blue-500\"\n      >\n        Hello world\n      </x.span>\n    </x.div>\n  </template>\n  <x.div fontSize=\"5xl\" fontWeight=\"extrabold\">\n    <x.span\n      backgroundClip=\"text\"\n      color=\"transparent\"\n      backgroundImage=\"gradient-to-r\"\n      gradientFrom=\"emerald-500\"\n      gradientTo=\"light-blue-500\"\n    >\n      Hello world\n    </x.span>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the background attachment of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "backgroundAttachment={{ md: \"scroll\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "backgroundAttachment=\"scroll\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div backgroundAttachment={{ md: 'scroll' }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
